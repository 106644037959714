import React from 'react'
import { graphql } from 'gatsby'
import PageWrapper from '../components/pageWrapper'
import YoutubeVideo from '../components/youtubeVideo'
import PageDescription from '../components/pageDescription'
import HeroImage from '../components/heroImage'
import Footer from '../components/footer'

const VideosPage = ({ data: { datoCmsVideoPage: { name, description, heroImage, videos } } }) => {
  return (
    <>
      <HeroImage media={heroImage}/>
      <PageWrapper>
        <PageDescription
          title={name}
          text={description}
        />
        {videos.map(({ originalId, youtubeLink: { providerUid, height, width } }) => (
          <YoutubeVideo
            key={originalId}
            providerUid={providerUid}
            width={width}
            height={height}
          />
        ))}
      </PageWrapper>
      <Footer/>
    </>
  )
}

export const query = graphql`
  query VideoPage {
    datoCmsVideoPage {
      name
      description
      heroImage {
        alt
        fluid(maxWidth: 1400, maxHeight: 630, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      videos {
        originalId
        youtubeLink {
          url
          title
          width
          height
          provider
          providerUid
          thumbnailUrl
        }
      }
    }
  }
`

export default VideosPage
